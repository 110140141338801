<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    visits: Object,
});

const goToCounselorPage = (counselorId) =>
    router.get(`/dashboard/hospital-verb-visits/${counselorId}`);

const clearQueryParams = () => router.replace('/dashboard/hospital-verb-visits');

const searchForm = reactive({
    start_date: '',
    end_date: '',
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/hospital-verb-visits', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Verbo nos hospitais">
        <Breadcrumb current-page-name="Verbo nos hospitais" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Verbo nos hospitais</h4>
                <Link
                    :href="`/dashboard/hospital-verb-visits/create`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                    <div class="font-bold col-12 col-sm-6">Baixar visitas</div>
                    <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                        <a
                            class="btn btn-danger fw-bold fs-12"
                            :href="`/dashboard/hospital-verb-visits/pdf/export?start_date=${searchForm.start_date}&end_date=${searchForm.end_date}`"
                        >
                            <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                        </a>
                        <a
                            class="btn btn-success ms-2 fw-bold fs-12"
                            :href="`/dashboard/hospital-verb-visits/excel/export?start_date=${searchForm.start_date}&end_date=${searchForm.end_date}`"
                        >
                            <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                        </a>
                    </div>
                </div>
                <hr class="mx-3" />
                <div class="row align-items-center p-3">
                    <div class="col-6">
                        <label for="startDate" class="form-label">Data de início</label>
                        <input
                            id="startDate"
                            v-model="searchForm.start_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-6">
                        <label for="endDate" class="form-label">Data de fim</label>
                        <input
                            id="endDate"
                            v-model="searchForm.end_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table
                            class="table m-0 align-middle table-hover"
                            id="table-hospital-verb-visits"
                        >
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th class="text-center" scope="col">Identificação da visita</th>
                                    <th class="text-center" scope="col">Vidas alcançadas</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(counselor, index) in visits.data"
                                    :key="index"
                                    @click="goToCounselorPage(counselor.id)"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="text-center">
                                        {{ counselor.identification }}
                                    </td>
                                    <td class="text-center">
                                        {{ counselor.total_people }}
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="4">
                                        <Pagination
                                            :data="visits.meta"
                                            reference-date="visits"
                                            identification="visitas"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

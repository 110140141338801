<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    childs: Object,
});

const goChildRequest = (id) => router.get(`/dashboard/children-consecration/${id}`);

const clearQueryParams = () => router.replace('/dashboard/children-consecration');

const searchForm = reactive({
    child_name: '',
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/children-consecration', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Consagrações">
        <Breadcrumb current-page-name="Consagrações" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Consagrações</h4>
                <Link
                    :href="`/dashboard/children-consecration/create`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome da criança</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            placeholder="Camila Gomes"
                            @input="compositionSeacher('child_name', $event)"
                        />
                    </div>
                </div>
            </div>

            <hr class="mb-0" />

            <div class="row p-3">
                <div class="table-responsive">
                    <table class="table m-0 align-middle table-hover" id="table-families">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">#</th>
                                <th scope="col">Nome da criança</th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">Idade criança</th>
                                <th class="text-center" scope="col">Data agendada</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(request, index) in childs.data"
                                :key="index"
                                @click="goChildRequest(request.id)"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{ request.child_name }}
                                </td>
                                <td class="text-center">
                                    <Badge :name="request.status" />
                                </td>
                                <td class="text-center">
                                    {{ request.child_legible_age }}
                                </td>
                                <td class="text-center">
                                    {{ request.scheduled_date }}
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="bg-white">
                            <tr>
                                <td colspan="4">
                                    <Pagination
                                        :data="childs.meta"
                                        reference-date="childs"
                                        identification="Crianças"
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

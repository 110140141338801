<script setup>
import { ref, onMounted } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

import { vMaska } from 'maska/vue';

const props = defineProps({
    member: Object,
    educationDegrees: Object,
    sexs: Object,
    maritalStatus: Object,
    waterBaptismTypes: Object,
    socialNetworks: Array,
    operators: Object,
    types: Object,
});

const form = useForm({
    _method: 'put',
    name: props.member.name,
    believer: props.member.believer ? true : false,
    sex: props.member.sex,
    member_date: props.member.member_date,
    type: props.member.type,
    identification_photo: props.member.identification_photo,
    education_degree: props.member.education_degree ?? props.educationDegrees[0].value,
    training: props.member.training,
    businessperson: props.member.businessperson ? true : false,
    cpf: props.member.cpf,
    rg: props.member.rg,
    ssp: props.member.ssp,
    profession: props.member.profession,
    alumni: false,
    shepherd: props.member.shepherd,
    birth_date: props.member.birth_date,
    naturalness: props.member.naturalness,
    nationality: props.member.nationality,
    comments: props.member.comments,
    new_birth_date: props.member.new_birth_date,
    marital_status: props.member.marital_status ?? props.maritalStatus[0].value,
    email: props.member.email,
    water_baptism: props.member.water_baptism,
    water_baptism_type: props.member.water_baptism_type ?? props.waterBaptismTypes[0].value,
    rhema: props.member.rhema ? true : false,
    rhema_completion_date: props.member?.rhema?.completion_date ?? null,
    emr: props.member.emr ? true : false,
    emr_completion_date: props.member?.emr?.completion_date ?? null,
    ermm: props.member.ermm ? true : false,
    ermm_completion_date: props.member?.ermm?.completion_date ?? null,
    baptized_holy_spirit: props.member.baptized_holy_spirit ? true : false,
    social_networks: [],
    phones: [],
    term_commitment: props.member.term_commitment,
});

const update = () => form.post(`/dashboard/members/${props.member.id}`);

const inputRef = ref(null);

const updateSocialNetwork = (index, value) => {
    form.social_networks[index].value = value;
};

const addPhone = () => {
    form.phones.push({
        phone: '',
        operador: props.operators[0].value,
        same_whatsapp_number: false,
    });
};

const setDefaultOperator = () => {
    form.phones = form.phones.map((phone) => ({
        ...phone,
        operador: phone.operador || props.operators[0].value,
    }));
};

onMounted(() => {
    form.social_networks = props.socialNetworks.map((network) => {
        const memberNetwork = props.member.social_networks.find(
            (n) => n.social_network === network.value
        );
        return { name: network.value, value: memberNetwork ? memberNetwork.nick : '' };
    });

    form.phones = props.member.cell_phone_numbers.map((phone) => {
        return {
            phone: phone.phone_number,
            operador: phone.operador,
            same_whatsapp_number: phone.same_whatsapp_number === 1,
        };
    });

    setDefaultOperator();
});

const removePhone = (index) => {
    if (confirm('Você tem certeza que deseja remover este telefone?')) {
        form.phones.splice(index, 1);
    }
};

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.identification_photo = e.target.result;
    };
    reader.readAsDataURL(blob);
};

const handleFileUpload = (event) => {
    const file = event.target.files[0];
    form.term_commitment = file;
};
</script>

<template>
    <Dashboard :page-title="member.name">
        <Breadcrumb
            :current-page-name="member.name"
            :link-list="[
                {
                    previous_page_url: 'members',
                    previous_page_name: 'Membros',
                },
            ]"
        />

        <div id="edit-member">
            <div class="row d-flex align-items-center justify-content-between mb-sm-3">
                <div class="col-sm-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>

                <div class="col-sm-6 mt-3 mt-sm-0 text-end mb-3 mb-sm-0">
                    <Link
                        :href="`/dashboard/members/${props.member.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Ver registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Atualizar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.identification_photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome do membro </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="type" class="form-label">Status</label>
                    <select
                        id="type"
                        v-model="form.type"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in types" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-3">
                    <label for="sex" class="form-label">Sexo</label>
                    <select
                        id="sex"
                        v-model="form.sex"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="email" class="form-label"> Email </label>

                    <input
                        id="email"
                        v-model="form.email"
                        type="email"
                        autocomplete="off"
                        class="form-control"
                        placeholder="email@email.com"
                    />
                    <small v-if="form.errors.email" class="text-danger mt-3">
                        {{ form.errors.email }}
                    </small>
                </div>

                <div class="col-md-3">
                    <label for="type" class="form-label">Estado civil</label>
                    <select id="type" v-model="form.marital_status" class="form-select">
                        <option
                            v-for="(item, index) in maritalStatus"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <hr class="mb-0" />

                <legend>Trabalho e escolaridade</legend>

                <div class="col-md-5">
                    <label for="type" class="form-label">Grau de instrução</label>
                    <select
                        id="type"
                        v-model="form.education_degree"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option
                            v-for="(item, index) in educationDegrees"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-4">
                    <label for="training" class="form-label"> Formação </label>

                    <input
                        id="training"
                        v-model="form.training"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Formação"
                    />
                    <small v-if="form.errors.training" class="text-danger mt-3">
                        {{ form.errors.training }}
                    </small>
                </div>

                <div class="col-md-3 d-flex align-items-end justify-content-end">
                    <div class="form-check form-switch d-flex align-items-center mb-2">
                        <input
                            id="flexCheckDefault"
                            v-model="form.businessperson"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                        />
                        <label class="form-check-label ms-3" for="flexCheckDefault">
                            É empresário?
                        </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for="profession" class="form-label"> Profissão </label>

                    <input
                        id="profession"
                        v-model="form.profession"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Profissão"
                    />
                    <small v-if="form.errors.profession" class="text-danger mt-3">
                        {{ form.errors.profession }}
                    </small>
                </div>

                <hr class="mb-0" />

                <legend>Dados pessoais</legend>

                <div class="col-md-5">
                    <label for="cpf" class="form-label"> CPF </label>

                    <input
                        id="cpf"
                        v-maska
                        v-model="form.cpf"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="000.000.000-00"
                        data-maska="###.###.###-##"
                    />
                    <small v-if="form.errors.cpf" class="text-danger mt-3">
                        {{ form.errors.cpf }}
                    </small>
                </div>

                <div class="col-md-5">
                    <label for="rg" class="form-label"> RG </label>

                    <input
                        id="rg"
                        v-maska
                        v-model="form.rg"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="00.000.000-00"
                        data-maska="##.###.###-##"
                    />
                    <small v-if="form.errors.rg" class="text-danger mt-3">
                        {{ form.errors.rg }}
                    </small>
                </div>

                <div class="col-md-2">
                    <label for="ssp" class="form-label"> SSP </label>

                    <input
                        id="ssp"
                        v-model="form.ssp"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="BA"
                    />
                    <small v-if="form.errors.ssp" class="text-danger mt-3">
                        {{ form.errors.ssp }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="naturalness" class="form-label"> Naturalidade </label>

                    <input
                        id="naturalness"
                        v-model="form.naturalness"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Paulo Afonso"
                    />
                    <small v-if="form.errors.naturalness" class="text-danger mt-3">
                        {{ form.errors.naturalness }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="nationality" class="form-label"> Nacionalidade </label>

                    <input
                        id="nationality"
                        v-model="form.nationality"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Brasileiro"
                    />
                    <small v-if="form.errors.nationality" class="text-danger mt-3">
                        {{ form.errors.nationality }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="birth_date" class="form-label"> Data de nascimento </label>

                    <input
                        id="birth_date"
                        v-model="form.birth_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.birth_date" class="text-danger mt-3">
                        {{ form.errors.birth_date }}
                    </small>
                </div>

                <hr class="mb-0" />

                <legend>Rhema, EMR, ERMM, Alummni, Pastor</legend>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.rhema"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                        />
                        <label class="form-check-label ms-3" for="flexSwitchCheckChecked"
                            >Já fez o Rhema?</label
                        >
                    </div>
                </div>

                <div class="col-md-12" v-if="form.rhema">
                    <label for="rhema_completion_date" class="form-label">
                        Data de conclusão do RHEMA
                    </label>

                    <input
                        id="rhema_completion_date"
                        v-model="form.rhema_completion_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.rhema_completion_date" class="text-danger mt-3">
                        {{ form.errors.rhema_completion_date }}
                    </small>
                </div>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.emr"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                        />
                        <label class="form-check-label ms-3" for="flexSwitchCheckChecked"
                            >Já fez o EMR?</label
                        >
                    </div>
                </div>

                <div class="col-md-12" v-if="form.emr">
                    <label for="emr_completion_date" class="form-label">
                        Data de conclusão do EMR
                    </label>

                    <input
                        id="emr_completion_date"
                        v-model="form.emr_completion_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.emr_completion_date" class="text-danger mt-3">
                        {{ form.errors.emr_completion_date }}
                    </small>
                </div>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.ermm"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                        />
                        <label class="form-check-label ms-3" for="flexSwitchCheckChecked"
                            >Já fez o ERMM?</label
                        >
                    </div>
                </div>

                <div class="col-md-12" v-if="form.ermm">
                    <label for="emr_completion_date" class="form-label">
                        Data de conclusão do ERMM
                    </label>

                    <input
                        id="emr_completion_date"
                        v-model="form.ermm_completion_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.ermm_completion_date" class="text-danger mt-3">
                        {{ form.errors.ermm_completion_date }}
                    </small>
                </div>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.alumni"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                        />
                        <label class="form-check-label ms-3" for="flexSwitchCheckChecked"
                            >É Alumni?</label
                        >
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.shepherd"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                        />
                        <label class="form-check-label ms-3" for="flexSwitchCheckChecked"
                            >Pastor / Pastor Auxliar?</label
                        >
                    </div>
                </div>

                <hr class="mb-0" />

                <legend>Batismos e novo nascimento</legend>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.baptized_holy_spirit"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                        />
                        <label class="form-check-label ms-3" for="flexSwitchCheckChecked"
                            >Já recebeu o batismo no espírito santo?</label
                        >
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.water_baptism"
                            type="checkbox"
                            role="switch"
                            id="water_baptism"
                        />
                        <label class="form-check-label ms-3" for="water_baptism"
                            >Já recebeu o batismo nas Águas?</label
                        >
                    </div>
                </div>

                <div class="col-md-12" v-if="form.water_baptism">
                    <label for="type" class="form-label">Tipo de batismo nas águas</label>
                    <select id="type" v-model="form.water_baptism_type" class="form-select">
                        <option
                            v-for="(item, index) in waterBaptismTypes"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="new_birth_date" class="form-label"> Data do novo nascimento </label>

                    <input
                        id="new_birth_date"
                        v-model="form.new_birth_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.new_birth_date" class="text-danger mt-3">
                        {{ form.errors.new_birth_date }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="member_date" class="form-label"> Data que se tornou membro</label>

                    <input
                        id="member_date"
                        v-model="form.member_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.member_date" class="text-danger mt-3">
                        {{ form.errors.member_date }}
                    </small>
                </div>

                <hr class="mb-0" />

                <legend>Redes sociais</legend>

                <div class="col-md-12" v-for="(item, index) in form.social_networks" :key="index">
                    <div class="input-group mb-2">
                        <span class="input-group-text" id="basic-addon1">
                            <font-awesome-icon :icon="['fab', `${item.name.toLowerCase()}`]" />
                        </span>
                        <input
                            type="text"
                            v-model="item.value"
                            @input="updateSocialNetwork(index, item.value)"
                            class="form-control"
                            placeholder="Nome do usuário"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                </div>

                <hr class="mb-0" />

                <div class="d-flex justify-content-between align-items-center mb-3">
                    <legend class="col mb-0">Telefones de contato</legend>
                    <button type="button" class="btn btn-primary" @click="addPhone">
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </button>
                </div>

                <div
                    class="border p-3 mx-2 rounded-3"
                    v-for="(item, index) in form.phones"
                    :key="index"
                >
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <label for="inputPhone" class="form-label"
                                >Telefone {{ index + 1 }}</label
                            >
                            <input
                                v-maska
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                placeholder="(00) 00000-0000"
                                data-maska="(##) #####-####"
                                v-model="item.phone"
                            />
                        </div>
                        <div class="col-12 col-sm-6 mt-2 mt-sm-0">
                            <label for="inputOperator" class="form-label">Operadora</label>
                            <select id="inputOperator" class="form-select" v-model="item.operador">
                                <option
                                    v-for="(operator, operatorIndex) in operators"
                                    :key="operatorIndex"
                                    :value="operator.value"
                                >
                                    {{ operator.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col d-flex align-item-end justify-content-center">
                            <div class="form-check form-switch d-flex align-items-center mt-4">
                                <input
                                    class="form-check-input my-0"
                                    type="checkbox"
                                    role="switch"
                                    :id="`whatsapp${index}`"
                                    v-model="item.same_whatsapp_number"
                                />
                                <label class="form-check-label ms-3" :for="`whatsapp${index}`">
                                    Whatsapp
                                </label>
                            </div>
                        </div>
                        <div class="col d-flex align-items-end justify-content-center mt-4">
                            <div>
                                <button
                                    type="button"
                                    class="btn btn-danger mb-2"
                                    @click="removePhone(index)"
                                >
                                    Remover
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="mb-0" />

                <legend>Termo de consentimento</legend>

                <div class="col-md-12">
                    <label for="formFile" class="form-label"
                        >Selecione o documento preenchido pelo membro</label
                    >
                    <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        @change="handleFileUpload"
                        accept="application/pdf"
                    />
                </div>

                <hr class="mb-0" />

                <div class="col-md-12">
                    <label for="comments" class="form-label"> Observações </label>

                    <textarea
                        id="comments"
                        v-model="form.comments"
                        type="text"
                        class="form-control"
                        name="comments"
                        placeholder="Caso necessário, forneca alguma observação para esse membro"
                        rows="5"
                    />

                    <small v-if="form.errors.comments" class="text-danger mt-3">
                        {{ form.errors.comments }}
                    </small>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>

            <PhotoEditModal
                v-if="member.identification_photo"
                title="Atualizar foto de perfil"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>

<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    waterBaptism: Object,
    shepherds: Array,
    members: Object,
});

const initialMembers = props.waterBaptism.members || [];
const copiedMembers = reactive(
    initialMembers.map((member) => ({
        ...member,
        selected: initialMembers.some((m) => m.id === member.id),
    }))
);

const form = useForm({
    ...props.waterBaptism,
    responsible_id: props.waterBaptism.responsible.id,
    helper_id: props.waterBaptism.helper?.id || null,
    members: copiedMembers,
});

const selectedMemberIds = () => form.members.map((member) => member.id);

const update = () =>
    form
        .transform((data) => ({
            ...data,
            members: selectedMemberIds(),
        }))
        .put(`/dashboard/water-baptisms/${props.waterBaptism.id}`);

const toggleSelection = (field, id) => {
    form[field] = form[field] === id ? null : id;
};

const seacher = reactive({ member_name: '' });

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(search, 1000);
};

const search = () => {
    router.visit(`/dashboard/water-baptisms/edit/${props.waterBaptism.id}`, {
        method: 'get',
        data: { seacher },
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: (page) => {
            const currentSelectedMembers = form.members.map((member) => member.id);
            if (page.props.members) {
                const updatedMembers = page.props.members.map((member) => ({
                    ...member,
                    selected: currentSelectedMembers.includes(member.id),
                }));
                copiedMembers.splice(0, copiedMembers.length, ...updatedMembers);
            }
        },
    });
};

const toggleMemberSelection = (member) => {
    const index = form.members.findIndex((m) => m.id === member.id);
    if (index === -1) {
        form.members.push(member);
    } else {
        form.members.splice(index, 1);
    }
    member.selected = !member.selected;
};

const clearQueryParams = () =>
    router.replace(`/dashboard/water-baptisms/edit/${props.waterBaptism.id}`);

onMounted(() => {
    clearQueryParams();
});

const waterBaptismEditModalCloseButton = ref(null);

const destroy = () => {
    waterBaptismEditModalCloseButton.value.click();
    router.delete(`/dashboard/water-baptisms/${props.waterBaptism.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar dados da turma">
        <Breadcrumb
            current-page-name="Editar dados da turma"
            :link-list="[
                {
                    previous_page_url: 'water-baptisms',
                    previous_page_name: 'Turmas de batismo',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/water-baptisms/${waterBaptism.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#waterBaptismEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-5">
                <label for="identifier" class="form-label">Nome de identificação da turma</label>
                <input
                    id="identifier"
                    v-model="form.identifier"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Turma Março 2024"
                    required
                />
                <small v-if="form.errors.identifier" class="text-danger mt-3">{{
                    form.errors.identifier
                }}</small>
            </div>

            <div class="col-md-5">
                <label for="completion_date" class="form-label">Data de realização</label>
                <small class="optional ms-2">(opcional)</small>
                <input
                    id="completion_date"
                    v-model="form.completion_date"
                    type="datetime-local"
                    autocomplete="off"
                    class="form-control"
                />
                <small v-if="form.errors.completion_date" class="text-danger mt-3">{{
                    form.errors.completion_date
                }}</small>
            </div>

            <div class="col-md-2 d-flex align-items-end justify-content-end">
                <div class="form-check d-flex align-items-center form-switch mb-2">
                    <input
                        id="flexCheckDefault"
                        v-model="form.open"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault">Aberta</label>
                </div>
            </div>

            <div class="col-md-12">
                <label for="address" class="form-label">Local de batismo</label>
                <small class="optional ms-2">(opcional)</small>
                <input
                    id="address"
                    v-model="form.address"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Rua da Alegria - nº 100 - Centro"
                />
                <small v-if="form.errors.address" class="text-danger mt-3">{{
                    form.errors.address
                }}</small>
            </div>

            <div class="col-md-12">
                <label for="comments" class="form-label">Descrição</label>
                <small class="optional ms-2">(opcional)</small>
                <textarea
                    id="comments"
                    v-model="form.comments"
                    class="form-control"
                    placeholder="Forneça alguma descrição para essa turma"
                    rows="5"
                />
                <small v-if="form.errors.comments" class="text-danger mt-3">{{
                    form.errors.comments
                }}</small>
            </div>

            <hr class="mb-0" />
            <legend class="mb-4">Selecione o responsável pelo batismo</legend>

            <div class="row">
                <div
                    v-for="(item, index) in shepherds"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="item.identification_photo"
                            :class="{
                                selected: form.responsible_id == item.id,
                            }"
                            @click="toggleSelection('responsible_id', item.id)"
                        />
                        <span
                            v-show="form.responsible_id == item.id"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                    </label>
                </div>
            </div>

            <hr class="mb-0" />
            <legend class="mb-4">Selecione o auxiliar do responsável pelo batismo</legend>

            <div class="row">
                <div
                    v-for="(item, index) in shepherds"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="item.identification_photo"
                            :class="{ selected: form.helper_id == item.id }"
                            @click="toggleSelection('helper_id', item.id)"
                        />
                        <span
                            v-show="form.helper_id == item.id"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                    </label>
                </div>
            </div>

            <hr class="mb-0" />
            <legend class="mb-2">Selecione os membros participantes</legend>

            <div class="col-md-12">
                <label for="name" class="form-label">Adicionar membro</label>
                <input
                    id="name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="João Pedro de Souza"
                    @input="compositionSeacher('member_name', $event)"
                />
                <small v-if="form.errors.name" class="text-danger mt-3">{{
                    form.errors.name
                }}</small>
            </div>

            <ul class="list-group member-found col-d-12 p-2">
                <li
                    v-for="(item, index) in members"
                    :key="index"
                    class="list-group-item p-2 d-flex align-items-center mb-3"
                    :class="{ checked: item.selected }"
                >
                    <input
                        :id="'checkBoxVisitor' + item.id"
                        class="form-check-input me-1 mb-0"
                        type="checkbox"
                        name="listGroupRadio"
                        :value="item.id"
                        :checked="item.selected"
                        @click="toggleMemberSelection(item)"
                    />
                    <label
                        class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                        :for="'checkBoxVisitor' + item.id"
                        style="cursor: pointer"
                    >
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{{ item.name }}</span>
                            <a
                                :href="`/dashboard/members/${item.id}`"
                                class="btn btn-primary btn-add z-2 me-3"
                                target="_blank"
                                >Acessar perfil</a
                            >
                        </div>
                    </label>
                </li>
            </ul>

            <template v-if="form.members.length">
                <hr class="mb-0" />

                <legend class="mb-2">Membros adicionados à turma</legend>

                <div class="row mt-3">
                    <div
                        v-for="(item, index) in form.members"
                        :key="index"
                        class="col-md-2 text-center mb-3 col-6 mb-3 mb-sm-0"
                    >
                        <label class="image-checkbox position-relative">
                            <img class="img-responsive" :src="item.identification_photo" />
                            <span
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2"
                                @click="toggleMemberSelection(item)"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.name }}</span>
                        </label>
                    </div>
                </div>
            </template>
        </Form>
        <ConfirmationModal
            modal_id="waterBaptismEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="waterBaptismEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>

<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import ArtistCard from './Components/ArtistCard.vue';

const props = defineProps({
    artists: Object,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const searchForm = reactive({
    name: null,
});

const clearQueryParams = () => router.replace('/dashboard/artists');

const seacher = () =>
    router.get('/dashboard/artists', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Artistas">
        <Breadcrumb current-page-name="Artistas" />
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Artistas</h4>
                <Link href="/dashboard/artists/create" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome do artista</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="` `"
                            placeholder="Fernandinho"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>
            </div>

            <hr />

            <div class="row mt-3 px-3">
                <ArtistCard v-for="(item, index) in artists.data" :key="index" :data="item" />

                <Pagination
                    :data="artists.meta"
                    reference-date="artists"
                    identification="Artistas"
                />
            </div>
        </div>
    </Dashboard>
</template>

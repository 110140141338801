<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

import useStatus from '@/Pages/Dashboard/Composables/useStatus.vue';

const { formatStatusIcon, statusResponsePastor, statusResponseLeader } = useStatus();

const props = defineProps({
    requests: Object,
    departments: Object,
    requestStatus: Object,
});

const goDepartmentRequest = (id) => router.get(`/dashboard/departments/entry-requests/${id}`);

const clearQueryParams = () => router.replace('/dashboard/departments/entry-requests');

const searchForm = reactive({
    department_id: 0,
    status: 0,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/departments/entry-requests', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Solicitações Departamentos">
        <Breadcrumb current-page-name="Solicitações Departamentos" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Solicitações</h4>
                <Link
                    :href="`/dashboard/departments/entry-requests/create`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-7">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar departamento</label
                        >
                        <select
                            id="department_id"
                            v-model="searchForm.department_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todos os departamentos</option>
                            <option
                                v-for="(item, index) in departments"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Status da solicitação</label
                        >
                        <select
                            v-model="searchForm.status"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todos os status</option>
                            <option value="pending_by_pastor">Aguardando aprovação pastor</option>
                            <option value="pending_by_leader">Aguardando aprovação líder</option>
                            <option value="failed">Reprovadas</option>
                        </select>
                    </div>
                </div>
            </div>

            <hr class="mb-0" />

            <div class="row p-3">
                <div class="table-responsive">
                    <table class="table m-0 align-middle table-hover" id="table-families">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">#</th>
                                <th scope="col">Departamento</th>
                                <th scope="col">Membro solicitante</th>
                                <th class="text-center" scope="col">Status da solicitação</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(request, index) in requests.data"
                                :key="index"
                                @click="goDepartmentRequest(request.id)"
                                :class="{
                                    'table-secondary':
                                        request.status_response_pastor == 'PENDING' &&
                                        request.status_response_leader == 'PENDING',
                                    'table-info':
                                        request.status_response_pastor == 'APPROVED' &&
                                        request.status_response_leader == 'PENDING',
                                    'table-danger':
                                        request.status_response_pastor == 'NOT_APPROVED',
                                }"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{ request.department.name }}
                                </td>
                                <td>
                                    <img
                                        class="rounded-circle me-3"
                                        width="30"
                                        :src="request.member_photo"
                                        alt=""
                                    />
                                    <span>
                                        {{ request.member_name }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <font-awesome-icon
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :data-bs-title="
                                            statusResponsePastor(request.status_response_pastor)
                                        "
                                        :class="`fs-4 ${formatStatusIcon(request.status_response_pastor)}`"
                                        :icon="['fas', 'circle-check']"
                                    />
                                    <font-awesome-icon
                                        v-if="request.status_response_pastor != 'NOT_APPROVED'"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :data-bs-title="
                                            statusResponseLeader(request.status_response_leader)
                                        "
                                        :class="`fs-4 ${formatStatusIcon(request.status_response_leader)} ms-3`"
                                        :icon="['fas', 'circle-check']"
                                    />
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="bg-white">
                            <tr>
                                <td colspan="4">
                                    <Pagination
                                        :data="requests.meta"
                                        reference-date="requests"
                                        identification="Solicitações"
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

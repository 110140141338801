<script setup>
import { ref, onMounted } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const props = defineProps({
    artists: Object,
    themes: Object,
    music: Object,
    musicThemes: Object,
});

const form = useForm({
    _method: 'put',
    name: props.music.name,
    cover_photo: `/storage/${props.music.cover_photo}`,
    description: props.music.description,
    youtube_link: props.music.youtube_link,
    link_lyrics: props.music.link_lyrics,
    release_year: props.music.release_year,
    official_letter: props.music.official_letter,
    ministry_letter: props.music.ministry_letter,
    artist: props.music.artist_id,
    duration: props.music.duration,
    themes: [],
});

const inputRef = ref(null);

const update = () => form.post(`/dashboard/musics/${props.music.id}`);

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.cover_photo = e.target.result;
    };
    reader.readAsDataURL(blob);
};

const toggleArtistSelection = (artistId) => {
    form.artist = form.artist === artistId ? null : artistId;
};

const toggleThemeSelection = (themeId) => {
    const index = form.themes.indexOf(themeId);
    if (index === -1) {
        form.themes.push(themeId);
    } else {
        form.themes.splice(index, 1);
    }
};

onMounted(() => {
    form.themes = props.musicThemes.map((music) => music.id);
});
</script>

<template>
    <Dashboard page-title="Editar música">
        <Breadcrumb
            current-page-name="Editar música"
            :link-list="[
                {
                    previous_page_url: `musics/${music.id}`,
                    previous_page_name: `${music.name}`,
                },
            ]"
        />
        <div id="create-music">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar música</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/musics/${music.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.cover_photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="alert alert-danger mx-2" role="alert" v-if="form.errors.cover_photo">
                    {{ form.errors.cover_photo }}
                </div>

                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome da música </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Bondade de Deus"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="release_year" class="form-label"> Ano de lançamento </label>

                    <input
                        v-maska
                        id="release_year"
                        v-model="form.release_year"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        data-maska="####"
                        placeholder="2024"
                    />
                    <small v-if="form.errors.release_year" class="text-danger mt-3">
                        {{ form.errors.release_year }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="youtube_link" class="form-label"> Link do Youtube </label>

                    <input
                        id="youtube_link"
                        v-model="form.youtube_link"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="https://www.youtube.com/@verbodavidapauloafonso"
                    />
                    <small v-if="form.errors.youtube_link" class="text-danger mt-3">
                        {{ form.errors.youtube_link }}
                    </small>
                </div>

                <div class="col-md-8">
                    <label for="link_lyrics" class="form-label"> Link da Letra </label>

                    <input
                        id="link_lyrics"
                        v-model="form.link_lyrics"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="https://www.letras.mus.br/verbodavida"
                    />
                    <small v-if="form.errors.link_lyrics" class="text-danger mt-3">
                        {{ form.errors.link_lyrics }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="duration" class="form-label"> Duração da música </label>

                    <input
                        id="duration"
                        v-maska
                        v-model="form.duration"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        data-maska="##:##"
                        placeholder="00:00"
                    />
                    <small v-if="form.errors.duration" class="text-danger mt-3">
                        {{ form.errors.duration }}
                    </small>
                </div>

                <hr class="mb-0" />

                <div class="col-md-12">
                    <label for="official_letter" class="form-label"> Letra Oficial </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="official_letter"
                        v-model="form.official_letter"
                        type="text"
                        class="form-control"
                        name="official_letter"
                        placeholder="Forneça a letra oficial dessa música"
                        rows="20"
                    />

                    <small v-if="form.errors.official_letter" class="text-danger mt-3">
                        {{ form.errors.official_letter }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="ministry_letter" class="form-label"> Versão do Ministério </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="ministry_letter"
                        v-model="form.ministry_letter"
                        type="text"
                        class="form-control"
                        name="ministry_letter"
                        placeholder="Forneça a versão do ministério dessa música"
                        rows="20"
                    />

                    <small v-if="form.errors.ministry_letter" class="text-danger mt-3">
                        {{ form.errors.ministry_letter }}
                    </small>
                </div>

                <legend class="mb-4">Selecione os temas relacionados</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in themes"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`/storage/${item.photo_identification}`"
                                :class="{
                                    selected: form.themes.includes(item.id),
                                }"
                                @click="toggleThemeSelection(item.id)"
                            />
                            <span
                                v-show="form.themes.includes(item.id)"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>

                <hr class="mb-0" />

                <legend class="mb-4">Selecione os artistas relacionados</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in artists"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`/storage/${item.profile_photo}`"
                                :class="{
                                    selected: form.artist == item.id,
                                }"
                                @click="toggleArtistSelection(item.id)"
                            />
                            <span
                                v-show="form.artist == item.id"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>

            <PhotoEditModal
                v-if="form.cover_photo"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>

<script>
export default function useStatus() {
    const formatStatusIcon = (status) => {
        const style = {
            PENDING: {
                color: 'text-body-tertiary',
            },
            APPROVED: {
                color: 'text-success',
            },
            NOT_APPROVED: {
                color: 'text-danger',
            },
        };

        return style[status]?.color || 'text-success';
    };

    const statusResponsePastor = (status) => {
        const style = {
            PENDING: 'Aguardando a aprovação do pastor',
            APPROVED: 'Aprovada pelo pastor',
            NOT_APPROVED: 'Reprovada pelo pastor',
        };

        return style[status];
    };

    const statusResponseLeader = (status) => {
        const style = {
            PENDING: 'Aguardando a aprovação do líder',
            APPROVED: 'Aprovada pelo líder',
            NOT_APPROVED: 'Reprovada pelo líder',
        };

        return style[status];
    };

    return {
        formatStatusIcon,
        statusResponsePastor,
        statusResponseLeader,
    };
}
</script>

<script setup>
import { ref } from 'vue';
import { Head, useForm } from '@inertiajs/vue3';

const props = defineProps({ errors: Object });

const message = ref(null);
const form = useForm({ email: null });

const forgotPassword = () =>
    form.post('/forgot-password', {
        onSuccess: () => {
            form.reset();
            message.value = 'O email de redefinição de senha foi enviado com sucesso';
        },
    });
</script>

<template>
    <Head title="Esqueci a senha" />
    <div class="vh-100 d-flex justify-content-center align-items-center bg-secondary-subtle">
        <div class="col-md-7 col-lg-6 col-xxl-4">
            <div class="card border shadow-sm p-sm-5 p-4 rounded-3">
                <div class="row m-0">
                    <form class="form-card border-0 shadow-none" @submit.prevent="forgotPassword">
                        <img
                            class="mx-auto d-block mb-4"
                            width="150"
                            src="/img/verbo_vida_logo.png"
                            alt=""
                        />
                        <div class="mb-3">
                            <label for="email" class="form-label"
                                >Informe seu email para a redefinição de senha</label
                            >
                            <input
                                placeholder="exemplo@gmail.com"
                                type="email"
                                class="form-control"
                                name="email"
                                id="email"
                                v-model="form.email"
                                autocomplete="on"
                                aria-describedby="email"
                                required
                            />
                            <small class="text-danger mt-3" v-if="errors.email">
                                {{ errors.email }}
                            </small>
                        </div>
                        <button
                            class="btn btn-primary w-100 font-bold text-white mb-4"
                            type="submit"
                            :disabled="form.processing || message"
                        >
                            <span
                                v-if="form.processing"
                                class="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Enviar email
                        </button>
                        <div class="row">
                            <small class="text-center font-regular copyright">
                                Verbo da Vida Paulo Afonso
                                <font-awesome-icon class="ms-2" :icon="['fas', 'copyright']" />
                            </small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

import useStatus from '@/Pages/Dashboard/Composables/useStatus.vue';
import { left } from '@popperjs/core';

const { formatStatusIcon, statusResponsePastor, statusResponseLeader } = useStatus();

const props = defineProps({
    department: Object,
    members: Object,
    requests: Object,
    isLeader: Boolean,
});

const leaders = computed(() => {
    return props.members.data.filter(
        (member) =>
            member.status !== 'OFF' &&
            (member.function == 'LEADER' ||
                member.function == 'VICE_LEADER' ||
                member.function == 'SUPERVISOR')
    );
});

const team = computed(() => {
    return props.members.data.filter(
        (member) => member.status !== 'OFF' && member.function == 'MEMBER'
    );
});

const disconnectedMembers = computed(() => {
    return props.members.data.filter((member) => member.status == 'OFF');
});

const goDepartmentRequest = (id) => router.get(`/dashboard/departments/entry-requests/${id}`);
</script>

<template>
    <Dashboard :page-title="department.name">
        <Breadcrumb
            :current-page-name="department.name"
            :link-list="[
                {
                    previous_page_url: 'departments',
                    previous_page_name: 'Departamentos',
                },
            ]"
        />
        <div class="position-relative">
            <div
                class="rounded-3 border shadow-sm"
                :style="{ backgroundColor: department.identification_color }"
            >
                <img
                    width="100"
                    class="mx-auto d-block rounded-circle"
                    :src="`/storage/${department.image_identification}`"
                    alt=""
                />
            </div>
            <div class="position-absolute top-0 end-0 m-3">
                <Link
                    :href="`/dashboard/departments/edit/${department.id}`"
                    class="btn btn-light border"
                    :class="{ disabled: !isLeader }"
                    ><font-awesome-icon :icon="['fas', 'gear']"
                /></Link>
            </div>
        </div>

        <p
            v-if="department.description"
            class="p-3 bg-info bg-opacity-10 rounded-end informative-text mt-3"
            :style="{
                borderLeft: `5px solid ${department.identification_color}`,
            }"
        >
            {{ department.description }}
        </p>

        <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
            <div class="font-bold col-12 col-sm-6">Baixar membros</div>
            <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                <a
                    class="btn btn-danger fw-bold fs-12"
                    :href="`/dashboard/departments/members/pdf/export?department_id=${department.id}`"
                >
                    <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                </a>
                <a
                    class="btn btn-success ms-2 fw-bold fs-12"
                    :href="`/dashboard/departments/members/excel/export?department_id=${department.id}`"
                >
                    <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                </a>
            </div>
        </div>
        <hr class="mx-3" />

        <div class="p-3 row mt-2">
            <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link active d-flex align-items-center"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#personal-data"
                        type="button"
                        role="tab"
                        aria-controls="personal-data"
                        aria-selected="true"
                    >
                        <span class="badge text-bg-secondary me-2">{{
                            department.total_members
                        }}</span>
                        Membros
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link d-flex align-items-center"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                    >
                        <span class="badge text-bg-secondary me-2">{{ requests.length }}</span>
                        Solicitações de entrada
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link d-flex align-items-center"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        :disabled="true"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                    >
                        Relatórios
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link d-flex align-items-center"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        :disabled="true"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                    >
                        Escala
                    </button>
                </li>
            </ul>

            <div id="myTabContent" class="tab-content mt-3">
                <div
                    id="personal-data"
                    class="tab-pane fade show active card p-3"
                    role="tabpanel"
                    aria-labelledby="event-details"
                    tabindex="0"
                >
                    <div class="row d-flex justify-content-center">
                        <div
                            class="col-md-4 col-sm-6 col-12 mb-3"
                            v-for="(item, index) in leaders"
                            :key="index"
                        >
                            <div class="card card-simple p-3">
                                <div class="text-end">
                                    <Link
                                        class="btn btn-light border"
                                        :href="`/dashboard/departments/members/edit/${item.id}`"
                                        :class="{ disabled: !isLeader }"
                                        ><font-awesome-icon
                                            class="text-body-secondary"
                                            :icon="['fas', 'user-gear']"
                                    /></Link>
                                </div>
                                <Link :href="`/dashboard/members/${item.member.id}`">
                                    <img
                                        class="mx-auto d-block rounded-circle"
                                        :style="{
                                            border: `4px solid ${department.identification_color}`,
                                            width: '80px',
                                        }"
                                        :src="`${item.member.identification_photo}`"
                                        alt=""
                                    />
                                </Link>
                                <h6 class="text-center mt-2 font-bold">
                                    {{ item.member.clean_name }}
                                </h6>
                                <span class="mt-2 text-center">
                                    <Badge :name="item.function" />
                                </span>
                            </div>
                        </div>
                        <div
                            class="col-md-4 col-sm-6 col-12 mb-3"
                            v-for="(item, index) in team"
                            :key="index"
                        >
                            <div
                                class="card card-simple p-3"
                                :class="{
                                    'border border-info bg-info-subtle': item.status === 'INACTIVE',
                                }"
                            >
                                <div class="text-end">
                                    <Link
                                        class="btn btn-light border"
                                        :href="`/dashboard/departments/members/edit/${item.id}`"
                                        :class="{ disabled: !isLeader }"
                                        ><font-awesome-icon
                                            class="text-body-secondary"
                                            :icon="['fas', 'user-gear']"
                                    /></Link>
                                </div>
                                <Link :href="`/dashboard/members/${item.member.id}`">
                                    <img
                                        class="mx-auto d-block rounded-circle"
                                        :style="{
                                            border: `4px solid ${department.identification_color}`,
                                            width: '80px',
                                        }"
                                        :src="`${item.member.identification_photo}`"
                                        alt=""
                                    />
                                </Link>
                                <h6 class="text-center mt-2 font-bold">
                                    {{ item.member.clean_name }}
                                </h6>
                                <span class="mt-2 text-center">
                                    <Badge :name="item.function" />
                                </span>
                            </div>
                        </div>

                        <div
                            class="accordion"
                            id="accordionDisconnectedMembers"
                            v-if="disconnectedMembers.length"
                        >
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="false"
                                        aria-controls="collapseOne"
                                    >
                                        Membros que já serviram nesse departamento
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    class="accordion-collapse collapse"
                                    data-bs-parent="#accordionDisconnectedMembers"
                                >
                                    <div class="accordion-body">
                                        <div class="row d-flex justify-content-center">
                                            <div
                                                class="col-md-4 col-sm-6 col-12 mb-3"
                                                v-for="(item, index) in disconnectedMembers"
                                                :key="index"
                                            >
                                                <div
                                                    class="card card-simple p-3"
                                                    :class="{
                                                        'border border-info bg-info-subtle':
                                                            item.status === 'INACTIVE',
                                                    }"
                                                >
                                                    <div class="text-end">
                                                        <Link
                                                            class="btn btn-light border"
                                                            :href="`/dashboard/departments/members/edit/${item.id}`"
                                                            :class="{ disabled: !isLeader }"
                                                            ><font-awesome-icon
                                                                class="text-body-secondary"
                                                                :icon="['fas', 'user-gear']"
                                                        /></Link>
                                                    </div>
                                                    <Link
                                                        :href="`/dashboard/members/${item.member.id}`"
                                                    >
                                                        <img
                                                            class="mx-auto d-block rounded-circle"
                                                            :style="{
                                                                border: `4px solid ${department.identification_color}`,
                                                                width: '80px',
                                                            }"
                                                            :src="`${item.member.identification_photo}`"
                                                            alt=""
                                                        />
                                                    </Link>
                                                    <h6 class="text-center mt-2 font-bold">
                                                        {{ item.member.clean_name }}
                                                    </h6>
                                                    <span class="mt-2 text-center">
                                                        <Badge :name="item.status" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="profile-tab-pane"
                    class="tab-pane fade card p-3"
                    role="tabpanel"
                    aria-labelledby="contact-tab-pane"
                    tabindex="0"
                >
                    <div class="table-responsive search-form-card" v-if="requests.length">
                        <table class="table m-0 align-middle table-hover" id="table-families">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Membro solicitante</th>
                                    <th class="text-center" scope="col">Status da solicitação</th>
                                    <th class="text-center" scope="col">Data da solicitação</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(request, index) in requests"
                                    :key="index"
                                    @click="goDepartmentRequest(request.id)"
                                    :class="{
                                        'table-info':
                                            request.status_response_pastor == 'PENDING' &&
                                            request.status_response_leader == 'PENDING',
                                    }"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            :src="request.member_photo"
                                            alt=""
                                        />
                                        <span>
                                            {{ request.member_name }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <font-awesome-icon
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            :data-bs-title="
                                                statusResponsePastor(request.status_response_pastor)
                                            "
                                            :class="`fs-4 ${formatStatusIcon(request.status_response_pastor)}`"
                                            :icon="['fas', 'circle-check']"
                                        />
                                        <font-awesome-icon
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            :data-bs-title="
                                                statusResponseLeader(request.status_response_leader)
                                            "
                                            :class="`fs-4 ${formatStatusIcon(request.status_response_leader)} ms-3`"
                                            :icon="['fas', 'circle-check']"
                                        />
                                    </td>
                                    <td class="text-center">
                                        {{ request.created_at }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <p
                            class="p-3 bg-info bg-opacity-10 rounded-end informative-text"
                            :style="{
                                borderLeft: `5px solid ${department.identification_color}`,
                            }"
                        >
                            Nenhuma solicitação encontrada
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    shepherds: Object,
});

const form = useForm({
    identifier: null,
    open: false,
    comments: null,
    completion_date: null,
    address: null,
    responsible_id: null,
    helper_id: null,
});

const store = () => form.post('/dashboard/water-baptisms');

const toggleSelection = (id, type) => {
    if (type === 'responsible') {
        form.responsible_id = form.responsible_id === id ? null : id;
    } else if (type === 'helper') {
        form.helper_id = form.helper_id === id ? null : id;
    }
};
</script>

<template>
    <Dashboard page-title="Nova turma de batismo">
        <Breadcrumb
            current-page-name="Nova turma de batismo"
            :link-list="[
                {
                    previous_page_url: 'water-baptisms',
                    previous_page_name: 'Turma de batismos',
                },
            ]"
        />
        <h4 class="my-3">Nova turma de batismo</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-5">
                <label for="identifier" class="form-label"> Nome de identificação da turma </label>

                <input
                    id="identifier"
                    v-model="form.identifier"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Turma Março 2024"
                    required
                />
                <small v-if="form.errors.identifier" class="text-danger mt-3">
                    {{ form.errors.identifier }}
                </small>
            </div>

            <div class="col-md-5">
                <label for="completion_date" class="form-label"> Data de realização </label>
                <small class="optional ms-2">(opcional)</small>

                <input
                    id="completion_date"
                    v-model="form.completion_date"
                    type="datetime-local"
                    autocomplete="off"
                    class="form-control"
                    placeholder=""
                    required
                />
                <small v-if="form.errors.completion_date" class="text-danger mt-3">
                    {{ form.errors.completion_date }}
                </small>
            </div>

            <div class="col-md-2 d-flex align-items-end justify-content-end">
                <div class="form-check d-flex align-items-center form-switch mb-2">
                    <input
                        id="flexCheckDefault"
                        v-model="form.open"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault"> Aberta </label>
                </div>
            </div>

            <div class="col-md-12">
                <label for="address" class="form-label"> Local de batismo </label>
                <small class="optional ms-2">(opcional)</small>

                <input
                    id="address"
                    v-model="form.address"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Rua da Alegria - nº 100 - Centro"
                />
                <small v-if="form.errors.address" class="text-danger mt-3">
                    {{ form.errors.address }}
                </small>
            </div>

            <div class="col-md-12">
                <label for="comments" class="form-label"> Descrição </label>
                <small class="optional ms-2">(opcional)</small>

                <textarea
                    id="comments"
                    v-model="form.comments"
                    type="text"
                    class="form-control"
                    name="comments"
                    placeholder="Forneça alguma descrição para essa turma"
                    rows="5"
                />

                <small v-if="form.errors.comments" class="text-danger mt-3">
                    {{ form.errors.comments }}
                </small>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione o responsável pelo batismo</legend>

            <div class="row">
                <div
                    v-for="(item, index) in shepherds"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`${item.identification_photo}`"
                            :class="{
                                selected: form.responsible_id == item.id,
                            }"
                            @click="toggleSelection(item.id, 'responsible')"
                        />
                        <span
                            v-show="form.responsible_id == item.id"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione o auxiliar do responsável pelo batismo</legend>

            <div class="row">
                <div
                    v-for="(item, index) in shepherds"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`${item.identification_photo}`"
                            :class="{ selected: form.helper_id == item.id }"
                            @click="toggleSelection(item.id, 'helper')"
                        />
                        <span
                            v-show="form.helper_id == item.id"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>
        </Form>
    </Dashboard>
</template>

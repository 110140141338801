<script setup>
import { ref, onMounted } from 'vue';
import { Head, useForm, Link } from '@inertiajs/vue3';

import Toast from '@/Pages/Dashboard/Shared/Toast.vue';

const form = useForm({
    email: null,
    password: null,
});

const passwordInput = ref({
    icon: 'eye-slash',
    type: 'password',
});

const changePasswordVisibility = () => {
    passwordInput.value.type = passwordInput.value.type === 'password' ? 'text' : 'password';
    passwordInput.value.icon = passwordInput.value.type === 'password' ? 'eye-slash' : 'eye';
};

const code = ref(null);

const login = () => form.post('/login');

onMounted(() => {
    let url = new URL(window.location.href);
    let urlParams = new URLSearchParams(url.search);
    code.value = urlParams.get('code');
});
</script>

<template>
    <Head title="Login" />
    <Toast />
    <div id="login" class="d-flex justify-content-center align-items-center">
        <div class="card border-0 px-3 py-2 p-lg-0 shadow">
            <div class="row m-0">
                <div
                    id="imageSide"
                    class="col-lg-6 d-flex align-items-center justify-content-center d-none d-lg-block"
                />
                <div
                    class="col-lg-6 p-4 p-lg-0 col-md-8 mx-auto d-flex align-items-center justify-content-center mt-lg-0"
                >
                    <form @submit.prevent="login">
                        <img class="mx-auto d-block logo" src="/img/verbo_vida_logo.png" alt="" />
                        <legend class="text-center my-3 fs-5">Sistema de Gestão da Igreja</legend>

                        <div class="input-group mb-3">
                            <div class="form-floating is-invalid bg-light-subtle">
                                <input
                                    id="email"
                                    v-model="form.email"
                                    type="email"
                                    class="form-control bg-body-tertiary"
                                    placeholder="Email"
                                    autocomplete="on"
                                    required
                                />
                                <label for="email">Email</label>
                            </div>
                        </div>

                        <div class="input-group mb-3">
                            <div class="form-floating">
                                <input
                                    id="password"
                                    v-model="form.password"
                                    :type="passwordInput.type"
                                    class="form-control bg-body-tertiary border-end-0"
                                    placeholder="Senha"
                                    autocomplete="off"
                                    name="password"
                                    required
                                />
                                <label for="password">Senha</label>
                            </div>
                            <span
                                class="input-group-text bg-body-tertiary pe-auto"
                                @click="changePasswordVisibility"
                            >
                                <font-awesome-icon
                                    id="iconPassword"
                                    class="text-secondary"
                                    :icon="['fas', passwordInput.icon]"
                                />
                            </span>
                        </div>

                        <Link class="mb-3 float-end text-secondary" href="/forgot-password">
                            Esqueceu sua senha?
                        </Link>

                        <button
                            class="btn px-3 py-2 mt-2 text-white w-100 btn-success"
                            type="submit"
                            :disabled="form.processing"
                        >
                            <span
                                v-if="form.processing"
                                class="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            />
                            Acessar conta
                        </button>

                        <div
                            class="alert alert-danger text-center d-flex align-items-center justify-content-center mt-3"
                            role="alert"
                            v-if="form.errors.email"
                        >
                            {{ form.errors.email }}
                            <font-awesome-icon class="ms-2" :icon="['fas', 'circle-exclamation']" />
                        </div>

                        <div class="divider d-flex align-items-center my-4">
                            <p class="text-center mx-3 mb-0">Ou faça login com</p>
                        </div>

                        <Link
                            class="btn btn-light px-3 py-2 mt-3 text-dark w-100 d-flex align-items-center justify-content-center"
                            href="/dashboard/profile/sso/google"
                            style="border-style: dashed"
                        >
                            <img
                                src="/img/google_logo.png"
                                class="social-network-logo me-3"
                                alt=""
                            />
                            <span class="font-bold text-secondary"
                                >Entrar com a conta do Google</span
                            >
                        </Link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<template>
    <div>
        <div id="chart-bar">
            <VueApexCharts
                type="bar"
                ref="chartRef"
                height="300"
                :options="chartOptions"
                :series="formattedSeries"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script setup>
import VueApexCharts from 'vue3-apexcharts';
import { ref, reactive, defineProps, watch, computed } from 'vue';

// Props recebidas
const props = defineProps({
    labels: {
        type: Array,
        default: () => []
    },
    series: {
        type: Array,
        default: () => []
    }
});

// Referência para o gráfico
const chartRef = ref(null);

const chartOptions = reactive({
    chart: {
        type: 'bar',
        height: 300,
        toolbar: {
            tools: {
                download: false,
            },
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '50%',
            endingShape: 'rounded',
        },
    },
    xaxis: {
        categories: props.labels,
    },
    grid: {
        show: false,
    },
    pan: {
        enabled: false,
    },
    legend: {
        position: 'bottom',
        horizontalAlign: 'center',
    },
});

watch(
    () => props.labels,
    (newLabels) => {
        if (chartRef.value) {
            chartRef.value.updateOptions({
                xaxis: {
                    categories: newLabels
                }
            });
        }
    }
);

// Observa mudanças nas séries e atualiza os dados
watch(
    () => props.series,
    (newSeries) => {
        if (chartRef.value) {
            chartRef.value.updateSeries(newSeries);
        }
    }
);
const formattedSeries = computed(() => {
    return [
        {
            name: 'Série',
            data: props.series,
        },
    ];
});
</script>

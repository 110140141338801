<script>
import axios from 'axios';

export default function useIbge() {
    async function getCityArea(codIbge) {
        const url = `https://servicodados.ibge.gov.br/api/v2/malhas/${codIbge}?formato=application/vnd.geo+json`;

        try {
            const response = await axios.get(url);
            const data = response.data;
            const coordinates = data.features[0].geometry.coordinates[0];
            return coordinates;
        } catch (error) {
            console.error('Erro ao buscar os dados:', error);
            throw error; // Rejeita a promessa se ocorrer um erro
        }
    }

    async function getLocationByZipCode(cep) {
        const url = `https://viacep.com.br/ws/${cep}/json/`;

        try {
            const response = await axios.get(url);
            const data = response.data;
            return data;
        } catch (error) {
            //console.error('Erro ao buscar os dados:', error);
            //throw error; // Rejeita a promessa se ocorrer um erro
        }
    }

    return {
        getLocationByZipCode,
        getCityArea,
    };
}
</script>

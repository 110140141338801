<script setup>
import { ref } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    event: Object,
});

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';

const { simplifyDate } = useDateFormat();

const imageLoaded = ref(false);

const onImageLoaded = () => {
    imageLoaded.value = true;
};
</script>

<template>
    <div class="col-lg-4 col-md-6 mb-4">
        <div
            class="card event-card shadow-sm"
            :class="{ 'border border-success': event.status === 'ACTIVE' }"
        >
            <div class="card-img-top position-relative">
                <img
                    v-if="event.banner"
                    :src="`/storage/${event.banner}`"
                    class="banner"
                    alt=""
                    @load="onImageLoaded"
                />
                <div class="position-absolute top-0 text-white start-time p-2">
                    {{ simplifyDate(event.start_time) }}
                </div>

                <div
                    class="position-absolute bottom-0 end-0 text-white start-time p-2"
                    v-if="event.censu?.total_people"
                >
                    {{ event.censu?.total_people }} Presentes
                </div>
            </div>

            <div v-if="imageLoaded || !event.banner" class="card-body">
                <h5 class="card-title">
                    {{ event.name }}
                </h5>
                <hr class="mt-0" />

                <template v-if="event.type == 'CULT'">
                    <h6 class="mb-3">Dirigente do evento</h6>

                    <div class="row leadership-information justify-content-start">
                        <template v-if="event.leaders.length">
                            <Link
                                class="col-auto d-flex align-items-center"
                                :href="`/dashboard/leaders/${event.leaders[0].id}`"
                            >
                                <img
                                    :src="`/storage/${event.leaders[0].identification_photo}`"
                                    alt=""
                                />
                            </Link>
                            <div class="col-auto d-flex align-items-center ps-0">
                                <span>{{ event.leaders[0].name }}</span>
                            </div>
                        </template>
                        <template v-else>
                            <span>Nenhum dirigente relacionado</span>
                        </template>
                    </div>
                </template>

                <template v-if="event.type == 'PAID_EVENT'">
                    <h6 class="mb-3">Evento pago</h6>

                    <div class="font-semibold fs-12">
                        {{ event.registrations }} inscrições até o momento
                    </div>
                </template>
            </div>

            <div v-else class="card-body">
                <h5 class="card-title placeholder-glow">
                    <span class="placeholder col-6" />
                </h5>
                <p class="card-text placeholder-glow">
                    <span class="placeholder col-7" />
                    <span class="placeholder col-4" />
                    <span class="placeholder col-4" />
                    <span class="placeholder col-6" />
                    <span class="placeholder col-8" />
                    <span class="placeholder col-8" />
                </p>
            </div>

            <div class="card-footer d-flex justify-content-between bg-white align-items-center">
                <a
                    target="_blank"
                    class="btn btn-link text-danger p-0 fs-3"
                    :class="{ disabled: !event.youtube_url }"
                    :href="event.youtube_url"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    data-bs-title="Assistir no Youtube"
                >
                    <font-awesome-icon :icon="['fab', 'youtube']" />
                </a>

                <Link :href="`/dashboard/events/${event.id}`" class="btn btn-primary access-button">
                    Ver mais
                </Link>
            </div>
        </div>
    </div>
</template>

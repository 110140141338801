<script setup>
import { Head, useForm } from '@inertiajs/vue3';

const props = defineProps({ errors: Object });

const form = useForm({ code: null });
const login = () => form.post('/two-factor-challenge');
</script>

<template>
    <Head title="Código de confirmação" />
    <div class="vh-100 d-flex justify-content-center align-items-center bg-secondary-subtle">
        <div class="col-lg-5">
            <div class="card border shadow-sm p-5 rounded-3">
                <div class="row m-0 form-card border-0 shadow-none">
                    <form @submit.prevent="login">
                        <img
                            class="mx-auto d-block mb-4"
                            width="150"
                            src="/img/verbo_vida_logo.png"
                            alt=""
                        />
                        <div class="mb-3">
                            <label for="code" class="form-label"
                                >Digite o código gerado pelo seu aplicativo
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                name="code"
                                id="code"
                                v-model="form.code"
                                aria-describedby="emailHelp"
                                autocomplete="off"
                                maxlength="6"
                                placeholder="000 000"
                            />
                            <small class="text-danger mt-3" v-if="errors.code"
                                >Código informado é inválido, tente novamente.</small
                            >
                        </div>

                        <button
                            class="btn btn-primary w-100 font-bold text-white mb-4"
                            type="submit"
                            :disabled="form.processing"
                        >
                            <span
                                v-if="form.processing"
                                class="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Fazer login
                        </button>

                        <div class="row">
                            <small class="text-center font-regular copyright">
                                Verbo da Vida Paulo Afonso
                                <font-awesome-icon class="ms-2" :icon="['fas', 'copyright']" />
                            </small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

import FamilyOrganizationCad from './Components/FamilyOrganizationCard.vue';

const props = defineProps({
    family: Object,
});

const totalBelievers = computed(() => {
    return props.family.members.filter((member) => member.believer).length;
});

const totalWithMemberId = computed(() => {
    return props.family.members.filter((member) => member.church_member).length;
});
</script>

<template>
    <Dashboard :page-title="family.name">
        <div id="show-water-baptism">
            <Breadcrumb
                :current-page-name="family.name"
                :link-list="[
                    {
                        previous_page_url: 'families',
                        previous_page_name: 'Famílias',
                    },
                ]"
            />

            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-sm-6">
                    <h4 class="font-bold m-0">{{ family.name }}</h4>
                </div>
                <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/families/edit/${family.id}`"
                        class="btn btn-primary btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                        Editar dados
                    </Link>
                </div>
            </div>

            <div class="p-3 bg-info bg-opacity-10 border border-info" v-if="family.members.length">
                <font-awesome-icon class="me-2" :icon="['fas', 'people-roof']" /> Família composta
                por <span class="fw-bold">{{ family.members.length }}</span> membros, dos quais
                <span class="fw-bold">{{ totalWithMemberId }}</span> já fazem parte da nossa igreja
            </div>

            <div
                class="p-3 bg-warning bg-opacity-10 border border-warning my-3"
                v-if="totalBelievers < family.members.length"
            >
                <font-awesome-icon class="me-2" :icon="['fas', 'person-praying']" />
                <span class="fw-bold">{{ totalBelievers }}/{{ family.members.length }}</span>
                membros já aceitaram jesus nela
            </div>

            <FamilyOrganizationCad :family="family" />
        </div>
    </Dashboard>
</template>

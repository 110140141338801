<script setup>
import { reactive, ref, onMounted } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    counselor: Object,
    members: Object,
});

const form = useForm({
    _method: 'put',
    name: props.counselor.name,
    identification_photo: `/storage/${props.counselor.identification_photo}`,
    personal_summary: props.counselor.personal_summary,
    member_id: props.counselor.member_id,
    member_name: props.counselor.member?.name,
});

const inputRef = ref(null);

const store = () => form.post(`/dashboard/counselors/${props.counselor.id}`);

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.identification_photo = e.target.result;
    };
    reader.readAsDataURL(blob);
};

const seacher = reactive({
    member_name: null,
});

const search = () => {
    router.visit(`/dashboard/counselors/edit/${props.counselor.id}`, {
        method: 'get',
        data: { seacher },
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
        },
    });
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const toggleSelection = (field, id) => {
    if (form[field] === id) {
        form[field] = null;
    } else {
        form[field] = id;
    }
};

const clearQueryParams = () => router.replace(`/dashboard/counselors/edit/${props.counselor.id}`);

onMounted(() => {
    clearQueryParams();
    seacher.member_name = props.counselor.member?.name;
    search();
});

const counselorEditModalCloseButton = ref(null);

const destroy = () => {
    counselorEditModalCloseButton.value.click();
    router.delete(`/dashboard/counselors/${props.counselor.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar dados do conselheiro">
        <Breadcrumb
            :current-page-name="counselor.name"
            :link-list="[
                {
                    previous_page_url: 'counselors',
                    previous_page_name: 'Conselheiros',
                },
            ]"
        />

        <div id="edit-counselor">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/counselors/${counselor.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#counselorEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <Form
                button-name="Atualizar dados"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.identification_photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <label for="name" class="form-label"> Nome do conselheiro </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Micheline Paiva"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="personal_summary" class="form-label"> Observações </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="personal_summary"
                        v-model="form.personal_summary"
                        type="text"
                        class="form-control"
                        name="personal_summary"
                        placeholder="Forneça alguma observação"
                        rows="5"
                    />

                    <small v-if="form.errors.personal_summary" class="text-danger mt-3">
                        {{ form.errors.personal_summary }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="member_id" class="form-label"> Vincular a membro </label>

                    <input
                        id="member_id"
                        v-model="form.member_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        :placeholder="counselor.name"
                        @input="compositionSeacher('member_name', $event)"
                    />
                </div>

                <hr />

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in members"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.member_id === item.id }"
                    >
                        <input
                            :id="'checkBoxSpouse' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.member_id === item.id"
                            @change="toggleSelection('member_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxSpouse' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/members/${item.id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>
            <PhotoEditModal
                v-if="counselor.identification_photo"
                title="Atualizar foto de perfil"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
        <ConfirmationModal
            modal_id="counselorEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="counselorEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>

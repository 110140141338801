<script>
export default function useDateFormat() {
    function simplifyDate(dateString, full_date = true) {
        const months = [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
        ];

        let day, month, hour, minute;

        if (dateString.includes('/')) {
            const parts = dateString.split(' ');
            const dateParts = parts[0].split('/');
            const timeParts = parts[1].split(':');

            day = parseInt(dateParts[0]);
            month = parseInt(dateParts[1]);
            hour = parseInt(timeParts[0]);
            minute = parseInt(timeParts[1]);
        } else if (dateString.includes('-')) {
            const date = new Date(dateString);

            day = date.getUTCDate();
            month = date.getUTCMonth() + 1;
            hour = date.getUTCHours();
            minute = date.getUTCMinutes();
        }

        if (full_date) {
            return `${day} de ${months[month - 1]} às ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        } else {
            return `${day} de ${months[month - 1]}`;
        }
    }

    return {
        simplifyDate,
    };
}
</script>

<script setup>
import { defineProps } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    churches: Object,
});

const goTochurchPage = (churchId) => router.get(`/dashboard/churches/${churchId}`);
</script>

<template>
    <Dashboard page-title="Igrejas">
        <Breadcrumb current-page-name="Igrejas" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Igrejas</h4>
                <Link :href="`/dashboard/churches/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="row px-4 mt-3">
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-churches">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">ID</th>
                                <th scope="col">Nome</th>
                                <th class="text-center" scope="col">Modelo de igreja</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(church, index) in churches"
                                :key="index"
                                @click="goTochurchPage(church.id)"
                            >
                                <td class="text-center">
                                    {{ church.id }}
                                </td>
                                <td>
                                    <img
                                        class="rounded-circle me-3"
                                        width="30"
                                        :src="`/storage/${church.logo}`"
                                        alt=""
                                    />
                                    <span>
                                        {{ church.name }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <Badge :name="church.type" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    musics: Object,
    planning: Object,
    musicsPlanning: Object,
    planningColors: Object,
    colors: Object,
});

const initialMusics = props.musicsPlanning || [];
const copiedMusics = reactive(
    initialMusics.map((music) => ({
        ...music,
        selected: initialMusics.some((m) => m.id === music.id),
    }))
);

const form = useForm({
    musics: copiedMusics,
    colors: [],
    observations: props.planning.observations,
});

const selectedMusicsIds = () => form.musics.map((music) => music.id);

const store = () => {
    form.transform((data) => ({
        ...data,
        musics: selectedMusicsIds(),
    })).put(`/dashboard/musical-plannings/${props.planning.id}`);
};

const seacher = reactive({ music_name: '' });

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(search, 1000);
};

const search = () => {
    router.visit(`/dashboard/musical-plannings/edit/${props.planning.id}`, {
        method: 'get',
        data: { seacher },
        only: ['musics'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: (page) => {
            const currentSelectedMusics = form.musics.map((member) => member.id);
            if (page.props.musics) {
                const updatedMusics = page.props.musics.map((member) => ({
                    ...member,
                    selected: currentSelectedMusics.includes(member.id),
                }));
                copiedMusics.splice(0, copiedMusics.length, ...updatedMusics);
            }
        },
    });
};

const toggleMusicSelection = (music) => {
    const index = form.musics.findIndex((m) => m.id === music.id);
    if (index === -1) {
        form.musics.push(music);
    } else {
        form.musics.splice(index, 1);
    }
    music.selected = !music.selected;
};

const toggleColorSelection = (colorId) => {
    const index = form.colors.indexOf(colorId);
    if (index === -1) {
        form.colors.push(colorId);
    } else {
        form.colors.splice(index, 1);
    }
};

const clearQueryParams = () =>
    router.replace(`/dashboard/musical-plannings/edit/${props.planning.id}`);

onMounted(() => {
    clearQueryParams();
    form.colors = props.planningColors.map((music) => music.id);
});
</script>

<template>
    <Dashboard page-title="Editar planejamento">
        <Breadcrumb
            current-page-name="Editar planejamento"
            :link-list="[
                {
                    previous_page_url: `musical-plannings/${planning.id}`,
                    previous_page_name: 'Planejamento',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar planejamento</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/musical-plannings/${planning.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
            </div>
        </div>

        <Form
            button-name="Editar dados"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <legend>Selecione as músicas que serão tocadas</legend>

            <div class="col-md-12">
                <label for="name" class="form-label">Adicionar música</label>
                <input
                    id="name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Bondade de Deus"
                    @input="compositionSeacher('music_name', $event)"
                />
                <small v-if="form.errors.name" class="text-danger mt-3">{{
                    form.errors.name
                }}</small>
            </div>

            <ul class="list-group member-found col-d-12 p-2">
                <li
                    v-for="(item, index) in musics"
                    :key="index"
                    class="list-group-item p-2 d-flex align-items-center mb-3"
                    :class="{ checked: item.selected }"
                >
                    <input
                        :id="'checkBoxVisitor' + item.id"
                        class="form-check-input me-1 mb-0"
                        type="checkbox"
                        name="listGroupRadio"
                        :value="item.id"
                        :checked="item.selected"
                        @click="toggleMusicSelection(item)"
                    />
                    <label
                        class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                        :for="'checkBoxVisitor' + item.id"
                        style="cursor: pointer"
                    >
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{{ item.name }}</span>
                            <a
                                :href="`/dashboard/musics/${item.id}`"
                                class="btn btn-primary btn-add z-2 me-3"
                                target="_blank"
                                >Ver música</a
                            >
                        </div>
                    </label>
                </li>
            </ul>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione a paleta de cores</legend>

            <div class="row">
                <div
                    v-for="(item, index) in colors"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <div
                            :style="{
                                width: '50px',
                                height: '50px',
                                backgroundColor: `${item.color}`,
                                borderRadius: '50%',
                            }"
                            :class="{
                                selected: form.colors.includes(item.id),
                            }"
                            class="border border-3 border-info mx-auto d-block"
                            @click="toggleColorSelection(item.id)"
                        ></div>
                        <span
                            v-show="form.colors.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>

            <hr class="mb-0" />

            <template v-if="form.musics.length">
                <legend class="mb-2">Músicas adicionadas ao planejamento</legend>

                <div class="row mt-3">
                    <div
                        v-for="(item, index) in form.musics"
                        :key="index"
                        class="col-md-2 text-center mb-3 col-6 mb-3 mb-sm-0"
                    >
                        <label class="image-checkbox position-relative">
                            <img class="img-responsive" :src="`/storage/${item.cover_photo}`" />
                            <span
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2"
                                @click="toggleMusicSelection(item)"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.name }}</span>
                        </label>
                    </div>
                </div>
            </template>
            <hr class="mb-0" />
            <div class="col-md-12">
                <label for="observations" class="form-label"> Observações </label>
                <small class="optional ms-2">(opcional)</small>

                <textarea
                    id="observations"
                    v-model="form.observations"
                    type="text"
                    class="form-control"
                    name="observations"
                    placeholder="Forneça alguma observação para esse planejamento"
                    rows="5"
                />

                <small v-if="form.errors.observations" class="text-danger mt-3">
                    {{ form.errors.observations }}
                </small>
            </div>
        </Form>
    </Dashboard>
</template>

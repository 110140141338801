<script setup>
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    counselor: Object,
    newMembersBirths: Object,
    baptismsHolySpirit: Object,
});

const goToNewBirthPage = (memberId) => router.get(`/dashboard/new-births/${memberId}`);
const goToBaptismHolySpiritPage = (memberId) =>
    router.get(`/dashboard/baptism-holy-spirits/${memberId}`);
</script>

<template>
    <Dashboard :page-title="counselor.name">
        <div id="show-counselor">
            <Breadcrumb
                :current-page-name="counselor.name"
                :link-list="[
                    {
                        previous_page_url: 'counselors',
                        previous_page_name: 'Conselheiros',
                    },
                ]"
            />

            <div class="d-flex justify-content-between container-buttons">
                <Link
                    :href="`/dashboard/members/${counselor.member_id}`"
                    class="btn btn-add btn-success me-3"
                    :class="{ disabled: !counselor.member_id }"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Perfil membro
                </Link>
                <Link
                    :href="`/dashboard/counselors/edit/${counselor.id}`"
                    class="btn btn-add btn-primary"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar dados
                </Link>
            </div>

            <div class="mb-3 mt-3 mt-sm-0">
                <div id="counselor-details" class="row mb-3 px-3">
                    <div class="d-flex justify-content-center">
                        <img
                            class="identification_photo shadow-sm"
                            :src="`/storage/${counselor.identification_photo}`"
                            alt=""
                        />
                    </div>
                    <h4 class="text-center mt-4">
                        {{ counselor.name }}
                    </h4>
                    <p
                        v-if="counselor.personal_summary"
                        class="personal-summary mt-3 border-start border-5 border-info"
                    >
                        {{ counselor.personal_summary }}
                    </p>
                </div>

                <div class="p-3 row">
                    <ul
                        class="nav nav-tabs d-flex justify-content-center"
                        id="myTab"
                        role="tablist"
                    >
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#personal-data"
                                type="button"
                                role="tab"
                                aria-controls="personal-data"
                                aria-selected="true"
                            >
                                Novos nascimentos
                            </button>
                        </li>

                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="contact-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#contact-tab-pane"
                                type="button"
                                role="tab"
                                aria-controls="contact-tab-pane"
                                aria-selected="false"
                            >
                                Batismos ES
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content mt-3" id="myTabContent">
                        <div
                            class="tab-pane fade show active search-form-card p-4 mx-0"
                            id="personal-data"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabindex="0"
                        >
                            <div class="table-responsive" v-if="newMembersBirths.data.length">
                                <table class="table align-middle table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Nome do membro</th>
                                            <th class="text-center" scope="col">
                                                Tipo de novo nascimento
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(new_birth, index) in newMembersBirths.data"
                                            :key="index"
                                            @click="goToNewBirthPage(new_birth.id)"
                                        >
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ new_birth.member.name }}</td>
                                            <td class="text-center">
                                                <Badge :name="new_birth.type" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="bg-white">
                                        <tr>
                                            <td colspan="3">
                                                <span
                                                    v-if="newMembersBirths.data.length"
                                                    class="details float-end"
                                                >
                                                    {{ newMembersBirths.data.length }} registros
                                                    encontrados
                                                </span>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <p
                                v-else
                                class="p-3 m-3 bg-info bg-opacity-10 rounded-end informative-text border-primary border-start border-5"
                            >
                                Nenhum novo nascimento foi associado a este conselheiro até o
                                momento.
                            </p>
                        </div>
                        <div
                            class="tab-pane fade search-form-card p-4 mx-0"
                            id="contact-tab-pane"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                            tabindex="0"
                        >
                            <div class="table-responsive" v-if="baptismsHolySpirit.data.length">
                                <table class="table align-middle table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Nome do membro</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                baptism_holy_spirit, index
                                            ) in baptismsHolySpirit.data"
                                            :key="index"
                                            @click="
                                                goToBaptismHolySpiritPage(baptism_holy_spirit.id)
                                            "
                                        >
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ baptism_holy_spirit.member.name }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="bg-white">
                                        <tr>
                                            <td colspan="3">
                                                <span
                                                    v-if="baptismsHolySpirit.data.length"
                                                    class="details float-end"
                                                >
                                                    {{ baptismsHolySpirit.data.length }} registros
                                                    encontrados
                                                </span>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <p
                                v-else
                                class="p-3 m-3 bg-info bg-opacity-10 rounded-end informative-text border-primary border-start border-5"
                            >
                                Nenhum bastismo no espírito santo foi associado a este conselheiro
                                até o momento.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

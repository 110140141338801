<script setup>
import { ref, onMounted } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    team: Object,
    visit: Object,
});

const form = useForm({
    date_visit: props.visit.date_visit_gross,
    teams: [],
});

const update = () => form.put(`/dashboard/hospital-verb-visits/${props.visit.id}`);

const toggleTeamSelection = (memberId) => {
    const index = form.teams.indexOf(memberId);
    return index === -1 ? form.teams.push(memberId) : form.teams.splice(index, 1);
};

onMounted(() => {
    form.teams = props.visit.team.map((team) => team.id);
});

const peopleEditModalCloseButton = ref(null);

const destroy = () => {
    peopleEditModalCloseButton.value.click();
    router.delete(`/dashboard/hospital-verb-visits/${props.visit.id}`);
};
</script>

<template>
    <Dashboard page-title="Nova visita">
        <Breadcrumb
            current-page-name="Nova visita"
            :link-list="[
                {
                    previous_page_url: 'hospital-verb-visits',
                    previous_page_name: 'Verbo nos hospitais',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-sm-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                <Link
                    :href="`/dashboard/hospital-verb-visits/${visit.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#peopleEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-12">
                <label for="date_visit" class="form-label"> Data de visita ao hospital</label>
                <input
                    id="date_visit"
                    v-model="form.date_visit"
                    type="date"
                    autocomplete="off"
                    class="form-control"
                />
                <small v-if="form.errors.date_visit" class="text-danger mt-3">
                    {{ form.errors.date_visit }}
                </small>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione os membros da equipe que participaram</legend>

            <div class="row">
                <div v-for="(item, index) in team" :key="index" class="col-md-2 col-6 text-center">
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.teams.includes(item.id),
                            }"
                            @click="toggleTeamSelection(item.id)"
                        />
                        <span
                            v-show="form.teams.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>
        </Form>
        <ConfirmationModal
            modal_id="peopleEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="peopleEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>

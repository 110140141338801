<script setup>
import { ref, onMounted, reactive } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const props = defineProps({
    members: Object,
    user: Object,
    status: Object,
    userRole: Object,
    roles: Object,
    userPermissions: Object,
    allPermissions: Object,
    churches: Object,
    userChurches: Object,
});

const form = useForm({
    _method: 'put',
    status: props.user.status,
    member_name: null,
    member_id: null,
    name: props.user.name,
    email: props.user.email,
    role_id: props.userRole.id || 1,
    profile_image_url: `${props.user.profile_image_url}`,
    permissions: [],
    churches: [],
});

const seacher = reactive({
    member_name: null,
});

const search = () => {
    router.visit(`/dashboard/users/edit/${props.user.id}`, {
        method: 'get',
        data: { seacher },
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
        },
    });
};

const inputRef = ref(null);

const store = () => form.post(`/dashboard/users/${props.user.id}`);

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.profile_image_url = e.target.result;
    };
    reader.readAsDataURL(blob);
};

onMounted(() => {
    clearQueryParams();

    form.permissions = props.userPermissions.map((permission) => permission.id);
    form.churches = props.userChurches.map((c) => c.churche_id);
    seacher.member_name = props.user.name;
    search();
});

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const toggleSelection = (field, id) => {
    if (form[field] === id) {
        form[field] = null;
    } else {
        form[field] = id;
    }
};

const clearQueryParams = () => router.replace(`/dashboard/users/edit/${props.user.id}`);
</script>

<template>
    <Dashboard page-title="Editar dados do usuário">
        <Breadcrumb
            :current-page-name="user.name"
            :link-list="[
                {
                    previous_page_url: 'users',
                    previous_page_name: 'Usuários',
                },
            ]"
        />

        <div id="edit-user">
            <div class="row mt-3 mb-3">
                <div class="col d-flex justify-content-between align-items-center">
                    <h4 class="mb-0">Editar dados</h4>
                    <Link :href="`/dashboard/users/${user.id}`" class="btn btn-success btn-add">
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Atualizar dados"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.profile_image_url"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <label for="name" class="form-label"> Nome do usuário </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Micheline Paiva"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="email" class="form-label"> Email </label>

                    <input
                        id="email"
                        v-model="form.email"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Micheline Paiva"
                        required
                    />
                    <small v-if="form.errors.email" class="text-danger mt-3">
                        {{ form.errors.email }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="type" class="form-label">Status</label>
                    <select id="type" v-model="form.status" class="form-select">
                        <option v-for="(item, index) in status" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6 col-md-3">
                    <label for="function" class="form-label">Função</label>
                    <select
                        class="form-select"
                        name="function"
                        id="function"
                        v-model="form.role_id"
                    >
                        <option v-for="(role, index) in roles" :key="index" :value="role.id">
                            {{ role.display_name }}
                        </option>
                    </select>
                    <small class="text-danger mt-3" v-if="form.errors.role">{{
                        form.errors.role
                    }}</small>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />

                <hr class="mb-0 p-0" />

                <ul class="list-group overflow-auto p-2">
                    <h5 class="font-bold mb-3">Igrejas vinculadas</h5>

                    <li
                        class="list-group-item p-3 d-flex align-items-center"
                        v-for="(item, index) in churches"
                        :key="index"
                    >
                        <div>
                            <input
                                class="form-check-input me-1"
                                type="checkbox"
                                :value="item.id"
                                :id="'checkBoxItem' + item.id"
                                :checked="form.churches.includes(item.id) ? true : false"
                                v-model="form.churches"
                            />
                        </div>
                        <label
                            class="form-check-label stretched-link ms-3 fs-13"
                            :for="'checkBoxItem' + item.id"
                            style="cursor: pointer"
                            >{{ item.name }}</label
                        >
                    </li>
                </ul>

                <ul class="list-group overflow-auto p-2" id="permission-listing">
                    <h5 class="mb-4 font-bold">Definir permissões adicionais</h5>

                    <li
                        class="list-group-item p-3 d-flex align-items-center"
                        v-for="(permission, index) in allPermissions"
                        :key="index"
                    >
                        <div>
                            <input
                                class="form-check-input me-1"
                                type="checkbox"
                                :value="permission.id"
                                :id="'checkBoxPermission' + permission.id"
                                :checked="form.permissions.includes(permission.id) ? true : false"
                                v-model="form.permissions"
                            />
                        </div>
                        <label
                            class="form-check-label stretched-link ms-3 fs-13"
                            :for="'checkBoxPermission' + permission.id"
                            style="cursor: pointer"
                            >{{ permission.display_name }}</label
                        >
                    </li>
                </ul>

                <div class="col-md-12">
                    <label for="member_id" class="form-label"> Vincular a membro </label>

                    <input
                        id="member_id"
                        v-model="form.member_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        :placeholder="user.name"
                        @input="compositionSeacher('member_name', $event)"
                    />
                </div>

                <hr />

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in members"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.member_id === item.id }"
                    >
                        <input
                            :id="'checkBoxSpouse' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.member_id === item.id"
                            @change="toggleSelection('member_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxSpouse' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/members/${item.id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
            </Form>
            <PhotoEditModal
                v-if="user.profile_image_url"
                title="Atualizar foto de perfil"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>

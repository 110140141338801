<script setup>
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    color: Object,
});

const form = useForm({
    name: props.color.name,
    color: props.color.color,
});

const update = () => form.put(`/dashboard/color-palettes-praise/${props.color.id}`);
</script>

<template>
    <Dashboard page-title="Editar cor">
        <Breadcrumb
            current-page-name="Editar cor"
            :link-list="[
                {
                    previous_page_url: `color-palettes-praise/${color.id}`,
                    previous_page_name: 'Cores',
                },
            ]"
        />
        <div id="create-minister">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar cor</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/color-palettes-praise/${color.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome da cor </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Azul"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="color" class="form-label"> Código da cor </label>

                    <input
                        id="color"
                        v-model="form.color"
                        type="color"
                        autocomplete="off"
                        class="form-control"
                        required
                    />
                    <small v-if="form.errors.color" class="text-danger mt-3">
                        {{ form.errors.color }}
                    </small>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>

<script setup>
import VueApexCharts from 'vue3-apexcharts';
import { ref, reactive, defineProps } from 'vue';

const props = defineProps({
    series: {
        required: true,
        type: Array,
    },
    labels: {
        required: true,
        type: Array,
    },
});

const chart = ref(null);

const chartOptions = reactive({
    chart: {
        type: 'pie',
    },
    labels: props.labels,
    legend: {
        position: 'bottom',
        horizontalAlign: 'center',
    },
    dataLabels: {
        enabled: true,
        style: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        formatter: function (val) {
            return val.toFixed(1) + '%'; // Formata valores com porcentagem
        },
        dropShadow: {
            enabled: false, // Remove sombras nos textos
        },
    },
    plotOptions: {
        pie: {
            dataLabels: {
                offset: -30, // Ajusta posição para mais próximo do centro
            },
            donut: {
                labels: {
                    show: false, // Desativa labels de donut (caso use)
                },
            },
        },
    },
    colors: [
        '#3357FF', // Azul
        '#FF5733', // Vermelho
        '#33FF57', // Verde
        '#F9C74F', // Amarelo
        '#90BE6D', // Verde Claro
        '#43AA8B', // Verde Escuro
        '#F94144', // Vermelho Escuro
        '#577590', // Azul Acinzentado
        '#4D908E', // Verde Acinzentado
        '#277DA1', // Azul Escuro
        '#F3722C', // Laranja
        '#F8961E', // Laranja Claro
        '#F9844A', // Coral
        '#9A031E', // Vermelho Escuro
        '#5F0F40', // Roxo Escuro
        '#6A0572', // Roxo Vibrante
        '#7209B7', // Roxo
        '#4361EE', // Azul Claro
        '#4CC9F0', // Azul Neon
        '#3A86FF', // Azul Brilhante
    ],
});
</script>

<template>
    <div>
        <VueApexCharts
            type="pie"
            height="300"
            :options="chartOptions"
            :series="props.series"
        ></VueApexCharts>
    </div>
</template>

<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    colors: Object,
});

const goToColorPage = (musicId) => router.get(`/dashboard/color-palettes-praise/edit/${musicId}`);

const clearQueryParams = () => router.replace('/dashboard/color-palettes-praise');

const searchForm = reactive({
    name: null,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/color-palettes-praise', searchForm, { preserveState: true });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Cores">
        <Breadcrumb current-page-name="Cores" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Cores</h4>
                <Link
                    :href="`/dashboard/color-palettes-praise/create`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row g-3 px-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome da cor</label>
                        <input
                            id="floatingInput"
                            type="email"
                            v-model="searchForm.name"
                            class="form-control"
                            :value="` `"
                            placeholder="Azul"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table class="table m-0 align-middle table-hover" id="table-colors">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th class="text-center" scope="col">Nome</th>
                                    <th class="text-center" scope="col">Cor</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(color, colorIndex) in colors.data"
                                    :key="colorIndex"
                                    @click="goToColorPage(color.id)"
                                >
                                    <td class="text-center">
                                        {{ colorIndex + 1 }}
                                    </td>

                                    <td class="text-center">{{ color.name }}</td>
                                    <td class="text-center">
                                        <span
                                            class="p-2 text-white"
                                            :style="`background-color: ${color.color};`"
                                        >
                                            Exemplo
                                        </span>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="3">
                                        <Pagination
                                            :data="colors.meta"
                                            reference-date="colors"
                                            identification="Cores"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

import EventCardMember from '@/Pages/Dashboard/Pages/Events/Components/EventCardMember.vue';

const props = defineProps({
    child: Object,
    event: Object,
});

const getValueOrDefault = (value, defaultValue = 'Não informado') => {
    return value ? value : defaultValue;
};
</script>

<template>
    <Dashboard :page-title="child.child_name">
        <div id="show-user">
            <Breadcrumb
                :current-page-name="child.child_name"
                :link-list="[
                    {
                        previous_page_url: 'children-consecration',
                        previous_page_name: 'Consagrações',
                    },
                ]"
            />

            <div class="mb-3">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-lg-4 col-6">
                        <h4 class="font-bold m-0">{{ child.child_name }}</h4>
                    </div>
                    <div class="col-lg-8 col-6 text-end">
                        <Link
                            :href="`/dashboard/children-consecration/edit/${child.id}`"
                            class="btn btn-add btn-primary"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                            Editar dados
                        </Link>
                    </div>
                </div>
            </div>

            <EventCardMember :event="event" v-if="event" />

            <div class="px-5 py-4 mb-3 card-simple">
                <div class="mb-3 text-end">
                    <Badge :name="child.status" />
                </div>
                <hr />
                <form class="g-3">
                    <fieldset class="row" disabled>
                        <div class="col-md-5">
                            <label for="child_name" class="form-label">Nome da criança</label>
                            <input
                                id="child_name"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                :value="getValueOrDefault(child.child_name)"
                            />
                        </div>

                        <div class="col-md-4">
                            <label for="scheduled_date_gross" class="form-label font-bold"
                                >Data agendada</label
                            >
                            <input
                                id="scheduled_date_gross"
                                type="date"
                                autocomplete="off"
                                class="form-control"
                                :value="getValueOrDefault(child.scheduled_date_gross)"
                            />
                        </div>

                        <div class="col-md-3 mt-2 mt-sm-0">
                            <label for="number" class="form-label">Idade</label>
                            <input
                                id="child_legible_age"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                :value="getValueOrDefault(child.child_legible_age)"
                            />
                        </div>

                        <div class="col-md-6 mt-3">
                            <label for="father_name" class="form-label">Nome do pai</label>
                            <input
                                id="father_name"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                :value="getValueOrDefault(child.father_name)"
                            />
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="mother_name" class="form-label font-bold"
                                >Nome da mãe</label
                            >
                            <input
                                id="mother_name"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                :value="getValueOrDefault(child.mother_name)"
                            />
                        </div>
                        <div class="col-md-12 mt-3">
                            <label for="observations" class="form-label"> Observações </label>

                            <textarea
                                id="observations"
                                v-model="child.observations"
                                type="text"
                                class="form-control"
                                name="observations"
                                rows="5"
                            />
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </Dashboard>
</template>

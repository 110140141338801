<script setup>
import { computed } from 'vue';
import { Link, router } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const props = defineProps({
    artist: Object,
    musics: Object,
});

const goToMusicPage = (musicId) => router.get(`/dashboard/musics/${musicId}`);
</script>

<template>
    <Dashboard :page-title="artist.name">
        <div id="show-artist">
            <Breadcrumb
                :current-page-name="artist.name"
                :link-list="[
                    {
                        previous_page_url: 'artists',
                        previous_page_name: 'Artistas',
                    },
                ]"
            />
            <div class="card-simple card p-4">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-3">
                        <div class="position-relative d-flex justify-content-center">
                            <img
                                class="rounded-3 shadow"
                                width="200px"
                                :src="`/storage/${artist.profile_photo}`"
                                alt=""
                            />
                            <div class="position-absolute top-0 end-0 m-3 me-5 me-sm-3">
                                <Link
                                    :href="`/dashboard/artists/edit/${artist.id}`"
                                    class="btn btn-light border"
                                >
                                    <font-awesome-icon :icon="['fas', 'pen-to-square']"
                                /></Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 mt-3 mt-sm-0">
                        <div class="row">
                            <div class="col">
                                <span class="font-semibold text-secondary">Artista</span>
                                <h2 class="font-bold">{{ artist.name }}</h2>
                            </div>
                        </div>
                        <!-- <div class="row mt-3 font-semibold ms-1">
                            Suas músicas foram tocadas 2 vezes neste mês
                        </div> -->
                    </div>
                </div>
                <hr />

                <template v-if="musics.length">
                    <h4 class="font-bold font-bold mb-0">Músicas</h4>
                    <span class="text-secondary font-semibold fs-12 mb-3"
                        >Relacionadas a esse artista</span
                    >
                    <div class="table-responsive search-form-card">
                        <table class="table m-0 align-middle table-hover" id="table-musics">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Youtube</th>
                                    <th class="text-center" scope="col">Duração</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(music, musicIndex) in musics"
                                    :key="musicIndex"
                                    @click="goToMusicPage(music.id)"
                                >
                                    <td class="text-center">
                                        {{ musicIndex + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-pill me-3"
                                            height="35"
                                            width="35"
                                            :src="`/storage/${music.cover_photo}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ music.name }}
                                        </span>
                                    </td>

                                    <td class="text-center">
                                        <a
                                            target="_blank"
                                            class="btn btn-link text-danger p-0 fs-3"
                                            :class="{ disabled: !music.youtube_link }"
                                            :href="music.youtube_link"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            data-bs-title="Assistir no Youtube"
                                        >
                                            <font-awesome-icon :icon="['fab', 'youtube']" />
                                        </a>
                                    </td>

                                    <td class="text-center">{{ music.duration }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </div>
    </Dashboard>
</template>

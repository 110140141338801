<script setup>
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    member: Object,
    functions: Object,
    status: Object,
});

const form = useForm({
    function: props.member.function,
    status: props.member.status,
});

const update = () => form.put(`/dashboard/departments/members/${props.member.id}`);
</script>

<template>
    <Dashboard :page-title="`Editar ${member.member.clean_name}`">
        <Breadcrumb
            :current-page-name="`Editar ${member.member.clean_name}`"
            :link-list="[
                {
                    previous_page_url: `departments/${member.department.id}`,
                    previous_page_name: `${member.department.name}`,
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Editar {{ member.member.clean_name }}</h4>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-6">
                    <label for="function" class="form-label">Cargo do membro</label>
                    <select
                        id="function"
                        v-model="form.function"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in functions" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="status" class="form-label">Status do membro</label>
                    <select
                        id="status"
                        v-model="form.status"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in status" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>

<script setup>
import { useForm, router, Link } from '@inertiajs/vue3';

import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    visit: Object,
    typesNewBirths: Object,
});

const form = useForm({
    name: null,
    phone_number: null,
    address: null,
    city: null,
    new_birth_type: props?.typesNewBirths?.[0]?.value || null,
    town: null,
    visit_id: props.visit.id,
});

const update = () => router.post(`/dashboard/hospital-verb-people`, form);
</script>

<template>
    <Dashboard page-title="Novo">
        <Breadcrumb
            current-page-name="Novo"
            :link-list="[
                {
                    previous_page_url: `hospital-verb-visits/${visit.id}`,
                    previous_page_name: 'Visita',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-sm-6">
                <h4 class="font-bold m-0">Novo registro</h4>
            </div>
            <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                <Link
                    :href="`/dashboard/hospital-verb-visits/${visit.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar visita
                </Link>
            </div>
        </div>

        <Form
            button-name="Cadastrar"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="row g-3">
                <div class="col-sm-6">
                    <label for="name" class="form-label">Nome</label>
                    <input
                        placeholder="João Pedro de Souza"
                        type="text"
                        v-model="form.name"
                        class="form-control"
                        name="name"
                        id="name"
                        autocomplete="on"
                        aria-describedby="nameHelp"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>
                <div class="col-sm-3">
                    <label for="new_birth_type" class="form-label">Tipo da ação</label>
                    <select
                        id="new_birth_type"
                        v-model="form.new_birth_type"
                        class="form-select"
                        required
                    >
                        <option
                            v-for="(item, index) in typesNewBirths"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-3">
                    <label for="phone_number" class="form-label"> Contato </label>

                    <input
                        id="phone_number"
                        v-model="form.phone_number"
                        v-maska
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="(00) 00000-0000"
                        data-maska="(##) #####-####"
                    />
                    <small v-if="form.errors.phone_number" class="text-danger mt-3">
                        {{ form.errors.phone_number }}
                    </small>
                </div>
                <div class="col-sm-8">
                    <label for="address" class="form-label">Endereço</label>
                    <input
                        placeholder=" Rua Juscelino Kubitschek"
                        type="text"
                        v-model="form.address"
                        class="form-control"
                        name="address"
                        id="address"
                        autocomplete="on"
                        aria-describedby="addressHelp"
                    />
                    <small v-if="form.errors.address" class="text-danger mt-3">
                        {{ form.errors.address }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <label for="city" class="form-label">Cidade</label>
                    <input
                        placeholder=" Rua Juscelino Kubitschek"
                        type="text"
                        v-model="form.city"
                        class="form-control"
                        name="city"
                        id="city"
                        autocomplete="on"
                        aria-describedby="cityHelp"
                    />
                    <small v-if="form.errors.city" class="text-danger mt-3">
                        {{ form.errors.city }}
                    </small>
                </div>
                <div class="col-md-12">
                    <div class="form-check d-flex align-items-center form-switch">
                        <input
                            id="flexCheckDefault"
                            v-model="form.town"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                        />
                        <label class="form-check-label ms-3" for="flexCheckDefault">
                            É povoado?
                        </label>
                    </div>
                </div>
            </div>
        </Form>
    </Dashboard>
</template>

<script setup>
import { defineProps } from 'vue';
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    teams: Object,
});

const form = useForm({
    date_visit: null,
    teams: [],
});

const store = () => form.post('/dashboard/hospital-verb-visits');

const toggleTeamSelection = (memberId) => {
    const index = form.teams.indexOf(memberId);
    return index === -1 ? form.teams.push(memberId) : form.teams.splice(index, 1);
};
</script>

<template>
    <Dashboard page-title="Nova visita">
        <Breadcrumb
            current-page-name="Nova visita"
            :link-list="[
                {
                    previous_page_url: 'hospital-verb-visits',
                    previous_page_name: 'Verbo nos hospitais',
                },
            ]"
        />
        <h4 class="my-3">Nova visita</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-12">
                <label for="date_visit" class="form-label"> Data de visita ao hospital</label>
                <input
                    id="date_visit"
                    v-model="form.date_visit"
                    type="date"
                    autocomplete="off"
                    class="form-control"
                    required
                />
                <small v-if="form.errors.date_visit" class="text-danger mt-3">
                    {{ form.errors.date_visit }}
                </small>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione os membros da equipe que participaram</legend>

            <div class="row">
                <div v-for="(item, index) in teams" :key="index" class="col-md-2 col-6 text-center">
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.teams.includes(item.id),
                            }"
                            @click="toggleTeamSelection(item.id)"
                        />
                        <span
                            v-show="form.teams.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>
        </Form>
    </Dashboard>
</template>
